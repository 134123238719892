import React from "react";
import styles from "./styles.module.scss";
import ArticleModel from "../../../../../shared/infra/http/httpClient/model/Article.model";
import ImageLoader from "../../../../../shared/presentation/utils/ImageLoader";
import {Link} from "react-router-dom";

interface ArticleClipProps {
    children: ArticleModel;
}

function ArticleBox({children}: ArticleClipProps): JSX.Element {
    return (
        <Link to={`/article/${children.id}`} className={styles.link}>
            <div className={styles.box}>
                <div className={styles.image}
                     style={{backgroundImage: `url(${ImageLoader.load(children.image_url)})`}} />
                <div className={styles.content}><h3>{children.title}</h3></div>
            </div>
        </Link>
    );
}

export default ArticleBox;
